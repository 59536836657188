import React from 'react';
import Snow from './layouts/snowBg'
import Footer from './layouts/footer'
import Music from './components/music'
import Index from './pages/index'

function App() {
  return (
    <div className="App">
      <Index />
      <Snow />
      <Footer />
      <Music />
    </div>
  );
}

export default App;
