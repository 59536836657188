import React, { Component } from 'react'
import './index.scss'
const miniLogon = require('../assets/images/mini.jpg')

export default class Index extends Component {
  constructor () {
    super()
    this.state = {
      hidden: true
    }
    this.onMini = this.onMini.bind(this)
  }
  onMini (e) {
    e.preventDefault()
    this.setState({
      hidden: !this.state.hidden
    })
  }
  render () {
    return (
      <div id="index">
        {/* <a className="job" href="https://fejob.s73.show" target="_blank"
          rel="noopener noreferrer"
        >
          FeJob
        </a>
        <a href="." className={this.state.hidden ? 'hidden' : ''} onClick={this.onMini}>
          miniprogram
          <div className="code">
            <img src={miniLogon} alt="" />
          </div>
        </a> */}
      </div>
    )
  }
}