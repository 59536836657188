import React from 'react'
import './footer.scss'

export default function Footer () {
  return (
    <div id='footer'>
      <div className="center">
        <a href="https://beian.miit.gov.cn" target="_blank"
          rel="noopener noreferrer"
        >
          浙ICP备2024066505号-1
        </a>
      </div>
    </div>
  )
}