import React from 'react'

export default class Music extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      player: null,
      playUrl: null
    }
  }

  componentWillUnmount () {
    this.state.player.pause()
  }
  
  componentDidMount () {
    this.initPlayer()
  }

  async initPlayer () {
    let playUrl = this.state.playUrl
    if (!playUrl) {
      const res = await fetch('/api/song')
      const result = await res.json()
      playUrl = result.location
    }
    const player = new Audio(playUrl)
    player.loop = true
    player.play()
    this.setState({
      playUrl,
      player
    })
  }
  
  render () {
    return (
      <div id="music"></div>
    )
  }
}